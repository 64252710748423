import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionExists: false,
  subscriptionInfos: {},
  isLoading: false, // Indicateur de chargement
  error: null, // Gestion des erreurs
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setSubscriptionExists: (state, action) => {
      console.log("Updating Subscription Exists: ", action.payload);
      state.isLoading = false;

      // Déterminer si l'abonnement est actif, incluant les abonnements 'free'
      const isActive = isSubscriptionActive(action.payload.subscriptionInfos);
      state.subscriptionExists = isActive;

      state.subscriptionInfos = {
        ...action.payload.subscriptionInfos,
        active: isActive,
      };
      state.error = null;
    },
    updateSubscriptionInfos: (state, action) => {
      state.subscriptionInfos = action.payload;
    },
    setSubscriptionError: (state, action) => {
      state.isLoading = false; // Arrêter le chargement en cas d'erreur
      state.error = action.payload; // Enregistrer l'erreur
      state.subscriptionExists = false;
      state.subscriptionInfos = {};
    },
    resetSubscription: (state) => {
      state.isLoading = false;
      state.subscriptionExists = false;
      state.subscriptionInfos = {};
      state.error = null; // Réinitialiser l'erreur lors du reset
    },
  },
});

// Fonction utilitaire pour vérifier si un abonnement est actif
export const isSubscriptionActive = (subscriptionInfos) => {
  // Considérer 'free' comme actif si 'active' est true
  return subscriptionInfos?.active === true;
};

// Export des actions et du reducer
export const {
  setSubscriptionLoading,
  setSubscriptionExists,
  updateSubscriptionInfos,
  setSubscriptionError,
  resetSubscription,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// MUI Components
import { IoIosInformationCircleOutline } from 'react-icons/io';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';

// API
import { getAllRecipes, fetchAllRecipePictures } from '../../../../api/recipesAPI';

// Images
import NutriA from '../../../../assets/nutri-score/NutriscoreA.png';
import NutriB from '../../../../assets/nutri-score/NutriscoreB.png';
import NutriC from '../../../../assets/nutri-score/NutriscoreC.png';
import NutriD from '../../../../assets/nutri-score/NutriscoreD.png';
import NutriE from '../../../../assets/nutri-score/NutriscoreE.png';

// Toast
import toast from 'react-hot-toast';

// Styles
import "./MobileRecipe.css";

function MobileRecipeCard({ recipe, isTablet, isDesktop }) {

    return (
        <>
            <div className="mobile-recipe-card">

                <Card sx={{ width: isDesktop ? 430 : isTablet ? 420 : 320, padding: 0 }} orientation="horizontal">
                    <AspectRatio ratio="3/4" sx={{ width: 110, height: "100%", borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                        {recipe.imageUrl ? (
                            <img
                                src={recipe.imageUrl}
                                alt={recipe.name}
                                className="card-img"
                                onError={() => console.error(`Erreur lors du chargement de l'image pour la recette ${recipe.name}`)}
                            />
                        ) : (
                            <span>Aucune image disponible</span>
                        )}
                    </AspectRatio>
                    <CardContent orientation="vertical" sx={{ display: "flex", justifyContent: "space-between", padding: 1 }}>
                        <div>
                            <Typography level="title-lg">
                                {recipe.name}
                            </Typography>

                        </div>
                        <div>
                            <Typography fontSize="xs" sx={{ textAlign: "justify" }}>
                                <div className="mobile-recipe-card-description">
                                    {recipe.description.length > (isDesktop ? 200 : 150)
                                        ? `${recipe.description.slice(0, isDesktop ? 200 : 100)}...`
                                        : recipe.description}
                                </div>
                            </Typography>

                        </div>
                        {recipe.portions !== 0 && (
                            <div className='card-calories-score' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <Typography fontSize="sm" fontWeight="lg">
                                        {recipe.price + "€"}
                                    </Typography>
                                </div>
                                {recipe.nutriFacts && (
                                    <div className="card-calories" style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                        <div style={{ display: "flex", alignItems: "flex-end", gap: ".5rem" }}>
                                            <Chip variant="soft" color="primary">
                                                {parseInt(recipe.nutriFacts.calories / recipe.nutriFacts.portions) + " kcal"}
                                            </Chip>
                                            {/* Ajoutez ici votre logique pour obtenir l'image NutriScore */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </CardContent>
                </Card>

            </div >
        </>
    );
}

export default MobileRecipeCard;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// API
import { signup, verifyEmail } from "../../api/authAPI";

// Elements
import Button from "../../components/elements/button/Button";

// Styles
import "../login/Login.css";


import { setRoles } from "../../features/auth/authSlice";  // <-- Ajoute cette ligne


function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);

  // Local States
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validatePassword = (password) => {
    const hasEightCharacters = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

    return hasEightCharacters && hasUpperCase && hasNumber && hasSymbol;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!lastname || !firstname || !email || !password || !confirmPassword) {
      toast.error("Veuillez remplir tous les champs.");
      return;
    }
  
    if (!validatePassword(password)) {
      toast.error("Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un symbole.");
      return;
    }
  
    if (!emailFormat.test(email)) {
      toast.error("Veuillez entrer une adresse email valide.");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas.");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const emailExists = await verifyEmail(email);
      if (emailExists) {
        toast.error("Cet email est déjà utilisé.");
        setIsLoading(false);
        return;
      }
  
      const userData = await signup(firstname, lastname, email, password);
  
      // Vérification simplifiée pour l'inscription réussie
      if (userData && userData.message === "User signed up and sheet updated.") {
        toast.success("Votre compte a bien été créé.");
        navigate("/account");
      } else if (userData && userData.accessToken) {
        // Si le token d'authentification est fourni, on le stocke
        localStorage.setItem("authToken", userData.accessToken);


        // Dispatcher l'action setRoles avec les informations de l'utilisateur
        dispatch(setRoles(userData.role || "client"));  // Assure-toi que userData.role est bien défini, sinon "client"

        toast.success("Votre compte a bien été créé et vous êtes connecté.");
        navigate("/account");
      } else {
        toast.error("un autre type d'erreur lors de l'inscription.");
        console.error("Else error lors de l'inscription:", error);
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      toast.error("Erreur lors de l'inscription.");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="login-container">
      <h1>Inscription</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-content">
          <input
            type="text"
            placeholder="Nom"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            required
          />
        </div>
        <div className="input-content">
          <input
            type="text"
            placeholder="Prénom"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            required
          />
        </div>
        <div className="input-content">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-content">
          <div className="password-container">
            <input
              className="password-input"
              type={showPassword ? "text" : "password"}
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOffIcon size={10} /> : <VisibilityIcon size={10} />}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
        </div>
        <div className="input-content">
          <div className="password-container">
            <input
              className="password-input"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirmer le mot de passe"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <VisibilityOffIcon size={10} /> : <VisibilityIcon size={10} />}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
        </div>
        <div className="input-content">
          <Button
            className="btn-full"
            type="submit"
            disabled={isLoading}
            text={
              isLoading ? (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={24} color="inherit" style={{ marginRight: 8 }} />
                  Création du compte...
                </span>
              ) : (
                "S'inscrire"
              )
            }
          />
        </div>
      </form>
      <div className="create-account-link">
        <p>Déjà inscrit ?</p>
        <Link to="/login">Se connecter</Link>
      </div>
    </div>
  );
}

export default Signup;

import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../features/cart/cartSlice'; // Importing the action
import { setPreorderEnabled } from '../../features/preorder/preorderSlice';

// Toast
import toast from 'react-hot-toast';

// MUI components
import Chip from '@mui/joy/Chip';
import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// API
import { fetchNutriFacts } from '../../api/nutriFactsAPI';
import { fetchProductByRecipeId } from '../../api/stockAPI';

// Images
import NutriA from '../../assets/nutri-score/NutriscoreA.png';
import NutriB from '../../assets/nutri-score/NutriscoreB.png';
import NutriC from '../../assets/nutri-score/NutriscoreC.png';
import NutriD from '../../assets/nutri-score/NutriscoreD.png';
import NutriE from '../../assets/nutri-score/NutriscoreE.png';

// Components
import Button from '../elements/button/Button';
import PopupRecipeInfos from '../elements/popup/popup-recipe/PopupRecipeInfos';

function RecipeCard({ recipe }) {
    const [nutriFacts, setNutriFacts] = useState([]);
    const [ingredients, setIngredients] = useState({});
    const [openIngredientsPopup, setOpenIngredientsPopup] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const deliveryZones = ['93220', '93340', '93250', '93370', '93330'];
    const userPostalCode = sessionStorage?.getItem('userPostalCode');
    const isInDeliveryZone = deliveryZones.includes(userPostalCode);

    const recipeId = recipe.id;
    const dispatch = useDispatch();
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

    useEffect(() => {
        const fetchNutriInfos = async () => {
            try {
                const data = await fetchNutriFacts(recipeId);
                setNutriFacts(data);
            } catch (error) {
                console.error('Error fetching nutrition facts:', error);
            }
        };

        const fetchIngredients = async () => {
            try {
                const ingredients = await fetchProductByRecipeId(recipeId);
                setIngredients(ingredients);
            } catch (error) {
                console.error('Error fetching ingredients:', error);
            }
        };

        fetchNutriInfos();
        fetchIngredients();

        const cartExpiration = localStorage.getItem('cartExpiration');
        if (cartExpiration && new Date().getTime() > cartExpiration) {
            localStorage.removeItem('cart');
            localStorage.removeItem('cartExpiration');
            toast.info("Votre panier a expiré et a été vidé.");
        }
    }, [recipeId]);

    let nutriScoreImage;

    switch (nutriFacts?.nutriscore) {
        case 'A':
            nutriScoreImage = <img src={NutriA} alt="NutriA" />;
            break;
        case 'B':
            nutriScoreImage = <img src={NutriB} alt="NutriB" />;
            break;
        case 'C':
            nutriScoreImage = <img src={NutriC} alt="NutriC" />;
            break;
        case 'D':
            nutriScoreImage = <img src={NutriD} alt="NutriD" />;
            break;
        case 'E':
            nutriScoreImage = <img src={NutriE} alt="NutriE" />;
            break;
        default:
            nutriScoreImage = null;
    }

    const handleAddToCart = async () => {
        if (!isInDeliveryZone || (!preorderEnabled && recipe.qty_available === 0)) {
            toast.error(`${recipe.name} n'est pas disponible actuellement`);
            return;
        }

        let currentCart = JSON.parse(localStorage.getItem('cart')) || [];
        let currentTime = new Date().getTime();
        let cartExpiration = localStorage.getItem('cartExpiration');

        if (!currentCart.length || currentTime > cartExpiration) {
            currentCart = [];
            localStorage.removeItem('cartExpiration');
        }

        const itemIndex = currentCart.findIndex(item => item.id === recipe.id);
        const productQuantityInCart = itemIndex !== -1 ? currentCart[itemIndex].quantity : 0;

        if (
            productQuantityInCart < recipe.qty_available ||
            (preorderEnabled && recipe.qty_available === 0)
        ) {
            if (itemIndex !== -1) {
                currentCart[itemIndex].quantity += 1;
            } else {
                currentCart.push({
                    id: recipe.id,
                    name: recipe.name,
                    price: recipe.price,
                    quantity: 1
                });
            }

            localStorage.setItem('cart', JSON.stringify(currentCart));
            localStorage.setItem('cartExpiration', currentTime + 2 * 3600000);

            dispatch(addToCart(recipe));
            toast.success(`${recipe.name} a été ajouté au panier`);
        } else {
            toast.error(`La quantité maximale de ${recipe.name} est atteinte`);
        }
    };

    const handlePreorderClick = () => {
        dispatch(setPreorderEnabled(true));
        toast.success("Mode précommande activé !");
    };

    const buttonText = preorderEnabled && isInDeliveryZone
        ? "Pré-commander"
        : !isInDeliveryZone
            ? "Commander"
            : recipe.qty_available === 0 && !preorderEnabled
                ? "Revient bientôt -> Pré-commandez"
                : "Ajouter au panier";

    const imageUrl =
        recipe && recipe.pictures && recipe.pictures.length > 0
            ? `${process.env.REACT_APP_API_URL}${recipe.pictures[0].path}`
            : undefined;

    const buttonAction = recipe.qty_available === 0 && !preorderEnabled
        ? handlePreorderClick
        : handleAddToCart;

    return (
        <div className="card">
            <Card sx={{ width: isMobile ? '100%' : isTablet ? 330 : 420, padding: 0, height: isMobile ? "100%" : 480 }} orientation="vertical">
                <AspectRatio ratio="16/9" sx={{ width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt={recipe.name}
                            className="card-img"
                            onError={() => console.error(`Erreur lors du chargement de l'image pour la recette ${recipe.name}`)}
                        />
                    ) : (
                        <span>Aucune image disponible</span>
                    )}
                </AspectRatio>
                <CardContent orientation="vertical" sx={{ display: "flex", justifyContent: "space-between", paddingLeft: 2, paddingRight: 2 }}>
                    <div className='card-title-mobile'>
                        <div>
                            <Typography level="title-lg">{recipe.name}</Typography>
                        </div>
                        <div>
                            <Typography fontSize="sm" fontWeight="lg">{recipe.price + "€"}</Typography>
                        </div>
                    </div>
            </CardContent>
            <Typography className="card-content-description" fontSize="xs" sx={{ textAlign: "justify" }}>
                {recipe.description.length > 200 ? `${recipe.description.substring(0, 250)}...` : recipe.description}
            </Typography>
            <div>
                    <Typography className="card-content-ingredients"

                        level="body"
                        sx={{
                            fontSize: 14,
                            textDecoration: "underline",
                            color: "#1C4127",
                            cursor: "pointer"
                        }}
                        onClick={() => setOpenIngredientsPopup(true)}
                    >
                        ingrédients
                    </Typography>
                </div>
                <div className="card-title-infos">
                    <div className='card-calories-score'>
                        {nutriFacts &&
                            <div className="card-calories">
                                <Chip variant="soft" color="primary">
                                    {parseInt(nutriFacts?.calories / nutriFacts?.portions) + " kcal"}
                                </Chip>
                            </div>
                        }
                        <div>{nutriScoreImage}</div>
                    </div>
                </div>
                <Button
                    className={recipe.qty_available === 0 && !preorderEnabled && isInDeliveryZone ? "btn-disabled-mobile" : "btn-full-mobile"}
                    text={buttonText}
                    onClick={buttonAction}
                    disabled={!isInDeliveryZone}
                />
            </Card>
            <PopupRecipeInfos
                open={openIngredientsPopup}
                onClose={() => setOpenIngredientsPopup(false)}
                ingredients={ingredients}
                title={`Informations sur ${recipe.name}`}
                description={recipe.description}
            />
        </div>
    );
}

export default RecipeCard;

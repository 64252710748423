import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// API
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI"; // Corrigé ici
import { getOrderDetailsByOrderNum } from "../../api/ordersAPI"; // Corrigé ici


import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';
import { createInstantPayment } from '../../api/paygreenAPI';

// Elements

import Button from '../elements/button/Button';
import PopupFridge from '../elements/popup/popup-fridge/PopupFridge';

// Toast for notifications
import toast from 'react-hot-toast';

// Icons
import { FaRegUser, FaRegStar } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiBowlFood } from "react-icons/pi";

// Styles
import '../../views/admin-dashboard/AdminDashboard.css';

function ClientNavbar({ onSelectSection }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // États locaux
    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [orderAmount, setOrderAmount] = useState(null);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [totalAmount, setTotalAmount] = useState(null);

    // Ajout des états manquants
    const [readyOrderExist, setReadyOrderExist] = useState(false); // Commandes prêtes
    const [readyOrderNum, setReadyOrderNum] = useState(null); // Numéro de commande prête
    const [groupOrderExist, setGroupOrderExist] = useState(false); // Commandes groupées

    // Récupération des données du store Redux
    const { subscriptionExists, subscriptionInfos } = useSelector(state => state.subscription);

    // Récupérer les données nécessaires au paiement depuis localStorage
    useEffect(() => {
        const storedAmount = localStorage.getItem('total_amount');
        setTotalAmount(storedAmount);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userInfoData = await fetchCurrentUserInfos();
                setUserInfo(userInfoData);
    
                const ordersData = await fetchCurrentUserOrders();
                if (ordersData && ordersData.length > 0) {
                    const unpaidOrder = ordersData.find(order => order.status === "accepted" && !order.payment_status);
                    if (unpaidOrder) {
                        setUnpaidOrdersExist(true);
                        setUnpaidOrderNum(unpaidOrder.order_num);
                    } else {
                        setUnpaidOrdersExist(false);
                        setUnpaidOrderNum(null);
                    }
    
                    const readyOrder = ordersData.find(order => order.status === "ready");
                    if (readyOrder) {
                        setReadyOrderExist(true);
                        setReadyOrderNum(readyOrder.order_num);
                    } else {
                        setReadyOrderExist(false);
                        setReadyOrderNum(null);
                    }
                } else {
                    setUnpaidOrdersExist(false);
                    setUnpaidOrderNum(null);
                    setReadyOrderExist(false);
                    setReadyOrderNum(null);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données utilisateur :", error);
                toast.error("Erreur lors du chargement des données. Veuillez réessayer.");
            }
        };
    
        fetchData();
    }, []);

    const getUserSubscription = async (userId) => {
        try {
            const response = await fetchSubscription(userId);
            if (response.status === 200 && response.data) {
                const isActive = response.data.active;
                dispatch(setSubscriptionExists({
                    exists: isActive,
                    subscriptionInfos: { ...response.data, active: isActive },
                }));
            } else {
                dispatch(resetSubscription());
            }
        } catch (error) {
            console.error("Erreur lors de la récupération de l'abonnement :", error);
            dispatch(resetSubscription());
        }
    };

    const fetchOrderAmount = async (orderNum) => {
        try {
            console.log("Récupération du montant pour le numéro de commande :", orderNum);
    
            const orderDetails = await getOrderDetailsByOrderNum(orderNum);
            console.log("Commande trouvée :", orderDetails);
    
            if (orderDetails?.price) {
                console.log("Montant de la commande :", orderDetails.price);
                setOrderAmount(orderDetails.price); // Vérifiez que 'price' est bien une propriété
            } else {
                throw new Error("Montant introuvable pour cette commande.");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des détails de la commande :", error);
            toast.error("Impossible de récupérer les détails de la commande.");
        }
    };

    const handleSectionClick = (section) => {
        if ((section === "recipes" || section === "menulist") && (!subscriptionExists || !subscriptionInfos?.active)) {
            onSelectSection("subscriptions"); // Affiche la section abonnement sans changer de route
       }  else if (section === "recipes") {
        navigate('/recipes');
    } else if (section === "menulist") {
        navigate('/menulist');
    } else {
        console.error(`Section inconnue : ${section}`);
    }
};

        const initiatePaygreenPayment = async (orderNum, amount) => {
            try {
                if (!amount || !orderNum) {
                    throw new Error("Données de paiement manquantes.");
                }
                console.log(`Initier un paiement pour la commande ${orderNum} avec un montant de ${amount}`);
                const paymentResult = await createInstantPayment(amount, `${orderNum}`, orderNum);
        
                if (paymentResult?.instantPayment?.data?.hosted_payment_url) {
                    toast.success("Redirection vers la passerelle de paiement...");
                    window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
                } else {
                    throw new Error("URL de paiement non reçue.");
                }
            } catch (error) {
                console.error("Erreur lors du paiement Paygreen :", error);
                toast.error("Une erreur est survenue lors du paiement. Veuillez réessayer.");
            }
        };

        const handleButtonClick = async () => {
            try {
                if (unpaidOrdersExist && unpaidOrderNum) {
                    console.log("Commande non payée détectée :", unpaidOrderNum);
        
                    // Récupérer le montant et convertir en centimes
                    const amountToPay = parseFloat(orderAmount || totalAmount);
                    if (!amountToPay || isNaN(amountToPay)) {
                        throw new Error("Montant manquant ou invalide pour le paiement.");
                    }
        
                    const amountInCents = Math.round(amountToPay * 100); // Convertir en centimes
                    console.log("Montant en centimes pour le paiement :", amountInCents);
        
                    // Initier le paiement
                    await initiatePaygreenPayment(unpaidOrderNum, amountInCents);
                } else if (showPopupFridge) {
                    setShowPopupFridge(true);
                } else {
                    toast.error("Aucune commande disponible pour le paiement ou la récupération.");
                }
            } catch (error) {
                console.error("Erreur lors de la gestion du bouton :", error);
                toast.error("Une erreur est survenue. Veuillez réessayer.");
            }
        };
    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    return (
        <nav className="admin-navbar">
            <div className="admin-title">
                <h1>Bonjour {userInfo.firstname || "Stranger"}</h1>
            </div>
            <button className="admin-navbar-links" onClick={() => handleSectionClick("profil")}>
                <FaRegUser className="admin-navbar-icons" size={15} /> Profil
            </button>
            <button className="admin-navbar-links" onClick={() => handleSectionClick("orders")}>
                <TbTruckDelivery className="admin-navbar-icons" size={15} /> Commandes
            </button>
            {groupOrderExist && (
                <button className="admin-navbar-links" onClick={() => handleSectionClick("group-orders")}>
                    <TbTruckDelivery className="admin-navbar-icons" size={15} /> Suivi de commande
                </button>
            )}
            <button className="admin-navbar-links" onClick={() => handleSectionClick("subscriptions")}>
                <FaRegStar className="admin-navbar-icons" size={15} /> Abonnements
            </button>
            {subscriptionExists && subscriptionInfos.subscription_type === "pro_chef" && (
                <button className="admin-navbar-links" onClick={() => handleSectionClick("stocks")}>
                    <IoRestaurantOutline className="admin-navbar-icons" size={15} /> Produits
                </button>
            )}
            <button className="admin-navbar-links" onClick={() => handleSectionClick("recipes")}>
                <IoRestaurantOutline className="admin-navbar-icons" size={15} /> Recettes
            </button>
            <button className="admin-navbar-links" onClick={() => handleSectionClick("menulist")}>
                <PiBowlFood className="admin-navbar-icons" size={15} /> Menu Maker
            </button>
            <div className="client-action">
                {(unpaidOrdersExist || readyOrderExist) && (
                    <button className="btn-navbar" onClick={handleButtonClick}>
                        {unpaidOrdersExist ? "Payer la commande" : "Ouvrir le frigo"}
                    </button>
                )}
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </nav>
    );
}

export default ClientNavbar;

import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { getRecipesByMenuId } from '../../../api/menuRecipesAPI';
import { fetchRecipeById } from "../../../api/recipesAPI";

function MobileShoppingList({ productsInfos, menuInfos, updateShoppingListProduct }) {
    const [recipesPortions, setRecipesPortions] = useState({});
    const [recipesDetails, setRecipesDetails] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState({});
    const [selectedProducts, setSelectedProducts] = useState({});

    // Fetch recipe portions and details
    useEffect(() => {
        const fetchRecipePortionsAndDetails = async () => {
            try {
                const recipes = await getRecipesByMenuId(menuInfos.id);

                // Fetch recipe portions
                const recipesPortionsResult = recipes.reduce((acc, recipe) => {
                    acc[recipe.recipe_id] = recipe.portions;
                    return acc;
                }, {});
                setRecipesPortions(recipesPortionsResult);

                // Fetch each recipe's details
                const recipeDetailsPromises = recipes.map(async (recipe) => {
                    const recipeDetails = await fetchRecipeById(recipe.recipe_id);
                    return { recipeId: recipe.recipe_id, details: recipeDetails };
                });

                const resolvedDetails = await Promise.all(recipeDetailsPromises);

                // Store recipe details
                const recipesDetailsResult = resolvedDetails.reduce((acc, { recipeId, details }) => {
                    acc[recipeId] = details;
                    return acc;
                }, {});

                setRecipesDetails(recipesDetailsResult);
            } catch (error) {
                console.error('Error fetching recipes for menuId', menuInfos.id, ':', error);
            }
        };

        if (menuInfos.id) {
            fetchRecipePortionsAndDetails();
        }
    }, [menuInfos.id]);

    useEffect(() => {
        const initialSelectedProducts = {};
        productsInfos.forEach(product => {
            initialSelectedProducts[product.id] = product.tobuy === 0;
        });
        setSelectedProducts(initialSelectedProducts);
    }, [productsInfos]);

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterChange = (category) => (event) => {
        setFilters({ ...filters, [category]: event.target.checked });
    };

    const filteredCategories = Object.keys(filters).filter(key => filters[key]);

    // Group products by category
    const groupedProducts = productsInfos.reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '10px' }}>
                <IconButton onClick={handleFilterClick}>
                    <FilterListIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleFilterClose}
                >
                    {productsInfos.map(product => product.category)
                        .filter((category, index, self) => self.indexOf(category) === index)
                        .map((category, index) => (
                            <MenuItem key={index}>
                                <Checkbox
                                    checked={filters[category] || false}
                                    onChange={handleFilterChange(category)}
                                />
                                <ListItemText primary={category} />
                            </MenuItem>
                        ))}
                </Menu>
            </div>
            {Object.entries(groupedProducts).map(([category, products]) => (
                <div key={category}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>{category}</Typography>
                    <List>
                        {products
                            .filter(product =>
                                (!selectedCategory || product.category === selectedCategory) &&
                                (filteredCategories.length === 0 || filteredCategories.includes(product.category))
                            )
                            .map((product, index) => {
                                const adjustedUnitQty = (product.unitQty / (recipesDetails[product.recipesId]?.portions || 1)) * (recipesPortions[product.recipesId] || 1);
                                const pricePerGram = product.price / 1000; // Convert price per kg to price per gram
                                const totalPrice = adjustedUnitQty * pricePerGram;

                                return (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={product.name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography component="span" variant="body2" color="text.primary">
                                                        {adjustedUnitQty.toFixed(2)} {product.unitType} - {totalPrice.toFixed(2)} €
                                                    </Typography>
                                                    <br />
                                                    <Typography component="span" variant="body2" color="text.secondary">
                                                        {product.price} €/kg
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                checked={product.tobuy === false}
                                                onChange={() => updateShoppingListProduct(product.id)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                    </List>
                </div>
            ))}
        </div>
    );
}

export default MobileShoppingList;

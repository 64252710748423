import { useState, useEffect } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';

import { fetchNutriFacts } from '../../../../api/nutriFactsAPI';
import { fetchCombinedProductInfoById } from '../../../../api/stockAPI';
import { fetchAllRecipePictures } from '../../../../api/recipesAPI';

import NutriA from '../../../../assets/nutri-score/NutriscoreA.png';
import NutriB from '../../../../assets/nutri-score/NutriscoreB.png';
import NutriC from '../../../../assets/nutri-score/NutriscoreC.png';
import NutriD from '../../../../assets/nutri-score/NutriscoreD.png';
import NutriE from '../../../../assets/nutri-score/NutriscoreE.png';

function SelectRecipeCard({ selectedRecipe, isSelected }) {
    const [nutriFacts, setNutriFacts] = useState([]);
    const [imageUrl, setImageUrl] = useState(undefined);
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        const fetchNutriInfos = async () => {
            try {
                const data = await fetchNutriFacts(selectedRecipe.id);
                setNutriFacts(data);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchProductInfos = async () => {
            try {
                const productsDetails = await fetchCombinedProductInfoById(selectedRecipe.id);
                setProductData(productsDetails);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchRecipePictures = async () => {
            try {
                const pictures = await fetchAllRecipePictures();
                if (pictures && pictures.length > 0) {
                    const recipePicture = pictures.find(picture => picture.recipe_id === selectedRecipe.id);
                    if (recipePicture) {
                        setImageUrl(`${process.env.REACT_APP_API_URL}${recipePicture.path}`);
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des images des recettes:", error);
            }
        };

        fetchNutriInfos();
        fetchProductInfos();
        fetchRecipePictures();
    }, [selectedRecipe.id]);

    let nutriScoreImage;
    switch (nutriFacts?.nutriscore) {
        case 'A':
            nutriScoreImage = <img src={NutriA} alt="NutriA" style={{ width: 50 }} />;
            break;
        case 'B':
            nutriScoreImage = <img src={NutriB} alt="NutriB" style={{ width: 50 }} />;
            break;
        case 'C':
            nutriScoreImage = <img src={NutriC} alt="NutriC" style={{ width: 50 }} />;
            break;
        case 'D':
            nutriScoreImage = <img src={NutriD} alt="NutriD" style={{ width: 50 }} />;
            break;
        case 'E':
            nutriScoreImage = <img src={NutriE} alt="NutriE" style={{ width: 50 }} />;
            break;
        default:
            nutriScoreImage = null;
    }

    return (
        <div className="menu-recipe-card">
            <Card sx={{ width: 350,
                border: isSelected
                    ? '2px solid #FEDA15' // Bordure jaune si sélectionnée
                    : '1px solid #ddd',   // Bordure grise par défaut
                borderRadius: '8px',
                padding: '1rem',
                transition: 'transform 0.3s, border 0.3s',
                transform: isSelected ? 'scale(1.05)' : 'scale(1)', // Ajuste la taille selon la sélection
                '&:hover': {
                    transform: 'scale(1.05)', // Zoom au survol
                }, }} orientation="horizontal">
                <AspectRatio ratio="9/16" sx={{ width: 120 }}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt={selectedRecipe.name}
                            className="card-img"
                            onError={() => console.error(`Erreur lors du chargement de l'image pour la recette ${selectedRecipe.name}`)}
                        />
                    ) : (
                        <span>Aucune image disponible</span>
                    )}
                </AspectRatio>
                <CardContent orientation="vertical" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "80px" }}>
                        <Typography level="title-lg">
                            {selectedRecipe.name}
                        </Typography>
                    </div>
                    <div>
                        <Typography level="body-xs">
                            {selectedRecipe.description}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: ".5rem" }}>
                        {nutriFacts && nutriFacts.calories !== undefined && (
                            <Chip variant="soft" color="primary">
                                {parseInt(nutriFacts.calories / nutriFacts.portions) + " kcal"}
                            </Chip>
                        )}
                        {nutriScoreImage}
                    </div>
                </CardContent>
            </Card>
        </div >
    );
}

export default SelectRecipeCard;

import apiInstance from "./api";
import { createOrder } from './ordersAPI';

/**
 * Récupère tous les articles d'une commande par son ID.
 * @param {string} orderId - L'ID de la commande.
 * @returns {Promise<Object>} - Les données des articles de la commande.
 */
const getItemsByOrderId = async (orderId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(
      `${process.env.REACT_APP_API_URL}/items/all/${orderId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des articles de la commande:", error);
    throw error;
  }
};

/**
 * Ajoute un article à une commande existante ou en crée une nouvelle si nécessaire.
 * @param {Object} newItem - Les détails de l'article à ajouter.
 * @returns {Promise<Object>} - Les données de la commande mise à jour.
 */
const addItemToOrder = async (newItem) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    let currentOrder = JSON.parse(localStorage.getItem('currentOrder'));

    if (!currentOrder || currentOrder.status !== 'new') {
      console.log("Pas de commande active, création d'une nouvelle commande.");

      const orderData = {
        userId: newItem.userId,
        status: 'new',
        order_items: [newItem],
        totalTTC: newItem.price * newItem.quantity
      };

      currentOrder = await createOrder(orderData);
      localStorage.setItem('currentOrder', JSON.stringify(currentOrder));
    }

    const response = await apiInstance.post(
      `${process.env.REACT_APP_API_URL}/items/add`,
      { ...newItem, orderId: currentOrder.id },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'article à la commande:", error);
    throw error;
  }
};

/**
 * Met à jour la quantité d'un article dans une commande.
 * @param {string} orderId - L'ID de la commande.
 * @param {string} recipeId - L'ID de l'article.
 * @param {number} quantity - La nouvelle quantité.
 * @returns {Promise<Object>} - Les données de la commande mise à jour.
 */
const updateItemQuantityInOrder = async (orderId, recipeId, quantity) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/items/update-quantity`,
      { orderId, recipeId, quantity },
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la quantité de l'article dans la commande:", error);
    throw error;
  }
};

/**
 * Supprime un article d'une commande.
 * @param {string} orderId - L'ID de la commande.
 * @param {string} recipeId - L'ID de l'article à supprimer.
 * @returns {Promise<Object>} - Les données de la commande mise à jour.
 */
const removeItemFromOrder = async (orderId, recipeId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.delete(
      `${process.env.REACT_APP_API_URL}/items/remove`,
      {
        headers,
        data: { orderId, recipeId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'article de la commande:", error);
    throw error;
  }
};

/**
 * Ajoute un article à une commande existante sans recréer la commande.
 * @param {Object} newItem - Les détails de l'article à ajouter.
 * @returns {Promise<Object>} - Les données de la commande mise à jour.
 */
const addItemtoExistingOrder = async (newItem) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.post(
      `${process.env.REACT_APP_API_URL}/items/add`,
      newItem,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'article à la commande existante:", error);
    throw error;
  }
};

// Exporting all functions in one step (ensuring no duplicates)
export {
  getItemsByOrderId,
  addItemToOrder,
  updateItemQuantityInOrder,
  removeItemFromOrder,
  addItemtoExistingOrder,
};

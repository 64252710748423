import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscription } from "../../api/subsciptionAPI";
import { setSubscriptionExists, resetSubscription, isSubscriptionActive } from '../../features/subscriptions/subscriptionSlice';
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import { createInstantPayment } from "../../api/paygreenAPI"; // Assurez-vous d'importer votre API
import toast from 'react-hot-toast'; // Ajout de l'import
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import PopupFridge from "../../components/elements/popup/popup-fridge/PopupFridge";
import "../admin-dashboard/AdminDashboard.css";

function ClientMobile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Accéder aux informations d'abonnement depuis Redux
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderId, setUnpaidOrderId] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [orderError, setOrderError] = useState(null);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);


    // Fetch user info
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserInfo();
    }, []);

    // Fetch subscription info and orders/participants once user info is available
    useEffect(() => {
        const fetchUserOrderAndParticipant = async () => {
            try {
                const orders = await fetchCurrentUserOrders();
                const userId = userInfo.id;
                const isParticipant = await fetchParticipantByUserId(userId);
        
                if (orders && orders.length > 0) {
                    // Commande non payée
                    const unpaidOrder = orders.find(order => order.status === "accepted" && order.payment_status === false);
                    if (unpaidOrder) {
                        setUnpaidOrdersExist(true);
                        setUnpaidOrderNum(unpaidOrder.order_num); // Correct maintenant
                    } else {
                        setUnpaidOrdersExist(false);
                        setUnpaidOrderNum(null);
                    }
        
                    // Commande prête
                    const readyOrder = orders.find(order => order.status === "ready");
                    if (readyOrder) {
                        setReadyOrderExist(true);
                        setReadyOrderNum(readyOrder.order_num);
                    } else {
                        setReadyOrderExist(false);
                        setReadyOrderNum(null);
                    }
        
                    // Commande groupée
                    if (isParticipant && unpaidOrder && unpaidOrder.id === isParticipant?.order_id) {
                        setGroupOrderExist(true);
                    } else {
                        setGroupOrderExist(false);
                    }
                } else {
                    setUnpaidOrdersExist(false);
                    setReadyOrderExist(false);
                    setGroupOrderExist(false);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes :", error);
                setOrderError("Impossible de charger vos commandes. Veuillez réessayer plus tard.");
            }
        };
        

        if (userInfo.id) {
            fetchUserOrderAndParticipant();
        }

        const getUserSubscription = async () => {
    try {
        const response = await fetchSubscription(userInfo.id);
        if (response.status === 200 && response.data) {
            const isActive = isSubscriptionActive(response.data);
            dispatch(setSubscriptionExists({
                exists: isActive,
                subscriptionInfos: { ...response.data, active: isActive },
            }));
        } else {
            dispatch(resetSubscription());
        }
    } catch (error) {
        console.error("Erreur lors de la récupération de l'abonnement", error);
        dispatch(resetSubscription());
    }
};


        if (userInfo.id) {
            getUserSubscription();
        }
    }, [userInfo, dispatch]);

    // Log subscription details
    useEffect(() => {
        console.log("Subscription Exists: ", subscriptionExists);
        console.log("Subscription Infos: ", subscriptionInfos);
    }, [subscriptionExists, subscriptionInfos]);

    const initiatePaygreenPayment = async (orderNum) => {
        try {
            // Récupérer la commande par son numéro
            const orders = await fetchCurrentUserOrders(userInfo.id);
            const order = orders.find(o => o.order_num === orderNum);
    
            if (!order) {
                throw new Error("Commande introuvable.");
            }
    
            const amount = parseFloat(order.price); // Montant déjà en centimes
            if (isNaN(amount)) {
                throw new Error("Montant invalide pour la commande.");
            }
    
            console.log(`Initier un paiement pour la commande ${orderNum} avec un montant de ${amount} centimes.`);
    
            const paymentResult = await createInstantPayment(amount, `${orderNum}`, orderNum);
    
            if (paymentResult?.instantPayment?.data?.hosted_payment_url) {
                toast.success("Redirection vers la passerelle de paiement...");
                window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
            } else {
                throw new Error("Échec de la création du paiement Paygreen.");
            }
        } catch (error) {
            console.error("Erreur lors du paiement Paygreen :", error);
            toast.error("Le paiement a échoué. Veuillez réessayer.");
        }
    };
    

    const handleUserClick = () => {
        navigate("/account/profil");
    };

    const handleOrderClick = () => {
        navigate("/account/my-orders");
    };

    const handleGroupOrderClick = () => {
        if (groupOrderExist) {
            navigate("/account/orders-tracking");
        }
    };

    const handleSubscriptionClick = () => {
        navigate("/account/subscriptions");
    };

    const handleRecipeClick = () => {
        if (isSubscriptionActive(subscriptionInfos)) {
            navigate("/recipes");
        } else {
            toast.error("Accès réservé aux abonnés actifs.");
            navigate("/account/subscriptions");
        }
    };
    
    const handleMenuMakerClick = () => {
        if (isSubscriptionActive(subscriptionInfos)) {
            navigate("/account/menulist");
        } else {
            toast.error("Accès réservé aux abonnés actifs.");
            navigate("/account/subscriptions");
        }
    };

    const handleStockClick = () => {
        if (subscriptionExists && subscriptionInfos?.active && subscriptionInfos.subscription_type === "pro_chef") {
            navigate("/account/stock");
        } else {
            navigate("/account/subscriptions");
        }
    };

    const handleButtonClick = async () => {
        try {
            if (unpaidOrdersExist && unpaidOrderNum) {
                console.log("Commande non payée détectée :", unpaidOrderNum);
    
                // Initier le paiement Paygreen pour la commande non payée
                await initiatePaygreenPayment(unpaidOrderNum);
            } else {
                console.error("Aucune commande non payée ou prête trouvée.");
                toast.error("Aucune commande non payée disponible pour le paiement.");
            }
        } catch (error) {
            console.error("Erreur lors de la gestion du bouton de paiement :", error);
            toast.error("Une erreur est survenue. Veuillez réessayer.");
        }
    };
    

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    // Gérer l'état de chargement ou les erreurs
    if (isLoading) {
        return <div className="loading-screen"><h2>Chargement...</h2></div>;
    }

    if (orderError) {
        return <div className="error-message">{orderError}</div>;
    }

    return (
        <>
            <div className="admin-mobile-header">
                <h2>Hello {userInfo.firstname} 👋</h2>
                <Typography level="body-ms" textColor={"#f5f5f5"} paddingLeft={'1rem'}>Que voulez-vous faire aujourd'hui?</Typography>
            </div>
            <div className="admin-mobile-actions">
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleUserClick}>
                    Profil
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleOrderClick}>
                    Commandes
                </Button>
                {groupOrderExist && (
                    <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleGroupOrderClick}>
                        Commandes groupées
                    </Button>
                )}
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleSubscriptionClick}>
                    Abonnements
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleStockClick}>
                    Produits
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleRecipeClick}>
                    Recettes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleMenuMakerClick}>
                    Menus
                </Button>
                <Button color="danger" sx={{ width: "270px", height: '50px', marginBottom: "3rem" }} variant="outlined" size="lg"
                    onClick={handleButtonClick}>
                    {unpaidOrdersExist
                        ? "Payer la commande"
                        : readyOrderExist
                            ? "Ouvrir le frigo"
                            : "Commander un repas"}
                </Button>
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </>
    );
}

export default ClientMobile;

const buttonStyle = {
    borderColor: "#006d26",
    color: "#006d26",
    width: "270px",
    height: '50px'
};

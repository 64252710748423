import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUnavailableItems, setCartFromLocalStorage } from '../../features/cart/cartSlice'; 
import { fetchCurrentUserOrders } from '../../api/userAPI';
import { createOrder, updateOrderById } from '../../api/ordersAPI'; 
import toast from 'react-hot-toast'; 

import CircularProgress from '@mui/material/CircularProgress';
import Button from '../elements/button/Button';
import CartItem from './CartItem';
import './SideCart.css';

function SideCart({ closeCartDropdown, isMobile }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // Utiliser Redux pour récupérer le panier et le total TTC
    const cart = useSelector((state) => state.cart.items); 

    // Synchroniser le panier avec le localStorage au premier chargement
    useEffect(() => {
        dispatch(setCartFromLocalStorage()); 
    }, [dispatch]);

    // Gestion de l'expiration du panier
    useEffect(() => {
        try {
            const cartExpiration = localStorage.getItem('cartExpiration');
            if (cartExpiration && !isNaN(cartExpiration) && new Date().getTime() > parseInt(cartExpiration)) {
                dispatch(clearUnavailableItems()); 
                localStorage.removeItem('cart');
                localStorage.removeItem('cartExpiration');
                toast.info("Votre panier a expiré.");
            }
        } catch (error) {
            console.error("Error handling cart expiration:", error);
        }
    }, [dispatch]);

    // Calcul du total en euros pour affichage
    const total = useMemo(() => cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2), [cart]);

    // Conversion du total en centimes pour la base de données
    const totalInCents = Math.round(total * 100);

    const handleCheckout = async () => {
        setIsLoading(true);
        try {
            if (cart.length === 0) {
                toast.error('Votre panier est vide.');
                setIsLoading(false);
                return;
            }
    
            // Vérifie si l'utilisateur est connecté en recherchant un utilisateur dans le localStorage
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user || !user.accessToken) {
                toast.error('Veuillez vous connecter pour valider votre commande.');
                navigate('/login');
                setIsLoading(false);
                return;
            }
    
            const userId = user.id;
    
            // Récupérer les commandes existantes
            const orders = await fetchCurrentUserOrders(userId);
    
            // Trouver une commande éligible
            let currentOrder = orders.find(
                order =>
                    (order.status === 'new') ||
                    (order.status === 'accepted' && order.payment_status === false)
            );
    
            if (currentOrder) {
                // Si commande `accepted` mais non payée, la réinitialiser à `new`
                if (currentOrder.status === 'accepted' && currentOrder.payment_status === false) {
                    currentOrder.status = 'new';
                }
    
                // Vérifier si le contenu du panier a changé
                const currentOrderItems = currentOrder.order_items.map(item => ({
                    id: item.id,
                    quantity: item.quantity,
                }));
    
                const cartItems = cart.map(item => ({
                    id: item.id,
                    quantity: item.quantity,
                }));
    
                const isSameCart = JSON.stringify(currentOrderItems) === JSON.stringify(cartItems);
    
                if (!isSameCart || currentOrder.price !== totalInCents) {
                    // Mettre à jour la commande existante uniquement si le contenu ou le prix a changé
                    console.log("Mise à jour de la commande existante avec les nouveaux articles.");
                    currentOrder = {
                        ...currentOrder,
                        order_items: [...cart],
                        price: totalInCents,
                    };
                    await updateOrderById(currentOrder.id, currentOrder);
                } else {
                    console.log("La commande existante est déjà à jour. Aucun changement nécessaire.");
                }
            } else {
                // Créer une nouvelle commande si aucune commande éligible n'existe
                console.log("Aucune commande éligible trouvée. Création d'une nouvelle commande.");
                currentOrder = await createOrder({
                    userId: userId,
                    status: 'new',
                    payment_status: false,
                    order_items: [...cart],
                    price: totalInCents,
                });
            }
    
            // Sauvegarder la commande actuelle
            localStorage.setItem('currentOrder', JSON.stringify(currentOrder));
            toast.success("Commande prête pour validation !");
            navigate('/order-validation');
        } catch (error) {
            console.error("Erreur lors du traitement de la commande:", error);
            toast.error("Une erreur est survenue lors du traitement de la commande.");
        } finally {
            setIsLoading(false);
        }
    };
    
    
    return (
        <div className={`side-cart-container ${isMobile ? "dropdown-mobile-cart" : ""}`}>
            <div className="side-cart-items-list">
                <h2 className="side-cart-title flex-center">Ma commande</h2>
                <div className="side-cart-items">
                    {cart.length === 0 ? (
                        <p className="flex-center">Votre panier est vide.</p>
                    ) : (
                        cart.map((item) => <CartItem key={item.id} item={item} />)
                    )}
                </div>
            </div>
            {cart.length > 0 && (
                <>
                    <div className="side-cart-recap">
                        <h2 className="side-cart-total-title">Total de la commande</h2>
                        <div className="side-cart-total">{total} €</div>
                    </div>
                    <div className="side-cart-action">
                    <Button
                        className="btn-border btn-xl"
                        onClick={handleCheckout}
                        disabled={isLoading} // Désactiver le bouton pendant le chargement
                        text={
                            isLoading ? (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={24} color="inherit" style={{ marginRight: 8 }} />
                            Traitement en cours...</span>) : ("Valider la commande")
                        }
                    />
                    </div>
                </>
            )}
        </div>
    );
}

export default SideCart;

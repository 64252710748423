import apiInstance from "./api";

/**
 * Authentification auprès de Paygreen
 * @returns {Promise} Retourne les données d'authentification de Paygreen
 */
export const authenticatePaygreen = async () => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    if (!token) {
        console.error("Aucun token d'accès trouvé pour l'utilisateur.");
        throw new Error("Utilisateur non authentifié.");
    }

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "fr",
        "x-access-token": token,
    };

    try {
        console.log("Tentative d'authentification auprès de Paygreen...");
        const response = await apiInstance.post(
            `${process.env.REACT_APP_API_URL}/paygreen/auth`,
            null, // Pas de données dans le corps, juste des headers
            { headers }
        );

        console.log("Authentification Paygreen réussie :", response.data);
        return response.data;
    } catch (error) {
        console.error("Erreur lors de l'authentification Paygreen :", error);
        throw error;
    }
};

/**
 * Création d'un paiement instantané Paygreen
 * @param {number} amount - Montant du paiement (en centimes)
 * @param {string} description - Description du paiement
 * @param {string} orderNum - Numéro de la commande
 * @returns {Promise} URL de la passerelle de paiement
 */
export const createInstantPayment = async (amount, description, orderNum) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    if (!token) {
        console.error("Aucun token d'accès trouvé pour l'utilisateur.");
        throw new Error("Utilisateur non authentifié.");
    }

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "fr",
        "x-access-token": token,
        "Content-Type": "application/json",
    };

    try {
        // Étape 1 : Authentification Paygreen
        console.log("Tentative d'authentification avec Paygreen...");
        const paygreenAuth = await authenticatePaygreen();
        console.log("Réponse de l'authentification Paygreen :", paygreenAuth);

        if (!paygreenAuth || !paygreenAuth.data.token) {
            console.error("Authentification Paygreen échouée, signature manquante.");
            throw new Error("Problème d'authentification Paygreen.");
        }

        // Étape 2 : Récupération des informations utilisateur
        console.log("Récupération des informations utilisateur...");
        const userResponse = await apiInstance.get(`${process.env.REACT_APP_API_URL}/user/infos`, { headers });
        console.log("Informations utilisateur récupérées :", userResponse.data);

        const { firstname, lastname, email } = userResponse.data;

        // Étape 3 : Préparation des données pour Paygreen
        const paymentData = {
            buyerInfo: {
                first_name: firstname,
                last_name: lastname,
                email: email,
                reference: orderNum,
            },
            amount: amount,
            description: description,
            signature: paygreenAuth.data.token,
        };
        console.log("Données préparées pour la requête Paygreen :", paymentData);

        // Étape 4 : Création du paiement instantané
        console.log("Tentative de création du paiement instantané Paygreen...");
        const response = await apiInstance.post(
            `${process.env.REACT_APP_API_URL}/paygreen/createInstantPayment`,
            paymentData,
            { headers }
        );
        console.log("Réponse de l'API Paygreen :", response.data);

        return response.data;
    } catch (error) {
        // Gestion des erreurs
        if (error.response) {
            console.error("Erreur API :", error.response.data);
            console.error("Status :", error.response.status);
            console.error("Headers :", error.response.headers);
        } else if (error.request) {
            console.error("Aucune réponse de l'API :", error.request);
        } else {
            console.error("Erreur de configuration de la requête :", error.message);
        }
        throw error;
    }
};

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// API imports
import { createOrder, updateOrderById } from '../../api/ordersAPI';
import { createInstantPayment } from '../../api/paygreenAPI';
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from '../../api/userAPI';


// Component imports
import Button from '../../components/elements/button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

import CartItem from '../../components/side-cart/CartItem';
import OrderPrice from '../../components/orders/OrderPrice';
import OrderCondition from '../../components/orders/OrderCondition';
import PreorderDate from '../../components/orders/preorders/PreorderDate';
import Addresses from '../../components/orders/Addresses';
import TypeOfDelivery from '../../components/orders/TypeOfDelivery';

import '../../components/orders/Order.css';
import 'reactjs-popup/dist/index.css';

function OrderValidation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { selectedDate, selectedTime, selectedAddress, preorderEnabled } = useSelector(state => state.preorder);

    const cart = useSelector(state => state.cart.items);
    const totalTTC = useSelector(state => state.cart.totalTTC);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const storedDate = localStorage.getItem('selectedDate');
    const storedTime = localStorage.getItem('selectedTime');

    const finalSelectedDate = selectedDate || storedDate;
    const finalSelectedTime = selectedTime || storedTime;

    const isInDeliveryZone = JSON.parse(sessionStorage.getItem('isInDeliveryZone') || "false");
    const [userInfos, setUserInfos] = useState({});
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const totalInCents = Math.round(totalTTC * 100);

    useEffect(() => {
        async function init() {
            try {
                const userInfo = await fetchCurrentUserInfos();
                setUserInfos(userInfo);
                console.log("User info loaded:", userInfo);

                const orders = await fetchCurrentUserOrders(userInfo.id);
                console.log("Fetched orders for user:", orders);

                // Ne pas réinitialiser une commande payée (payment_status: true)
                const currentOrder = orders.find(
                    order => order.status === 'accepted' && order.payment_status === false
                );
                if (currentOrder) {
                    console.log("Commande acceptée non payée détectée, aucune action requise.");
                }
            } catch (error) {
                console.error("Failed to fetch user info or reset order:", error);
                toast.error("Une erreur est survenue lors du chargement des informations.");
            }
        }
        init();
    }, []);

    useEffect(() => {
        localStorage.setItem('total_amount', totalTTC.toString());
        localStorage.setItem('selectedDate', finalSelectedDate || '');
        localStorage.setItem('selectedTime', finalSelectedTime || '');
    }, [totalTTC, finalSelectedDate, finalSelectedTime]);
    

    const validateUserAndAddress = (user, isChecked, selectedAddress, isInDeliveryZone) => {
        if (!user.id) {
            toast.error('Veuillez vous connecter pour continuer');
            return false;
        }
        if (!isChecked) {
            toast.error('Acceptez les conditions pour continuer');
            return false;
        }
        if (!selectedAddress && preorderEnabled) {
            toast.error('Veuillez sélectionner une adresse de livraison');
            return false;
        }
        if (!isInDeliveryZone && preorderEnabled) {
            toast.error('Votre adresse de livraison n’est pas dans la zone de livraison');
            return false;
        }
        if (!finalSelectedDate && preorderEnabled) {
            toast.error('Veuillez sélectionner une date de livraison.');
            return false;
        }
        return true;
    };

    const getFormattedDeliveryTime = () => {
        if (!finalSelectedDate || !finalSelectedTime) {
            console.error("Delivery date or time not specified");
            return null;
        }

        const formattedDate = dayjs(finalSelectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const formattedTime = dayjs(finalSelectedTime, 'HH:mm').format('HH:mm:ss');
        const datetime = `${formattedDate} ${formattedTime}`;

        const formattedDateTime = dayjs(datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

        if (!dayjs(formattedDateTime).isValid()) {
            console.error("Invalid date or time provided");
            return null;
        }

        return formattedDateTime;
    };
    const updateLastNewOrder = async (userId, stripeAmount) => {
        try {
            const orders = await fetchCurrentUserOrders(userId);
    
            let currentOrder = orders.find(
                order => order.status === 'new' || (order.status === 'accepted' && order.payment_status === false)
            );
    
            if (!currentOrder) {
                console.log("Aucune commande active trouvée. Création d'une nouvelle commande.");
                currentOrder = await createOrder({
                    userId,
                    status: 'accepted',
                    payment_status: false,
                    price: stripeAmount,
                    order_items: cart.map(item => ({
                        id: item.id,
                        quantity: item.quantity,
                    })),
                });
            } else {
                const updatedOrder = {
                    ...currentOrder,
                    status: 'accepted',
                    payment_status: false,
                    price: stripeAmount,
                    order_items: cart.map(item => ({
                        id: item.id,
                        quantity: item.quantity,
                    })),
                };
    
                currentOrder = await updateOrderById(currentOrder.id, updatedOrder);
            }
    
            console.log("Commande mise à jour :", currentOrder);
    
            // Récupérer `orderId` et `order_num`
            const orderId = currentOrder.data?.id;
            const orderNum = currentOrder.data?.order_num;
    
            if (!orderId || !orderNum) {
                throw new Error("Impossible de récupérer l'ID ou le numéro de la commande.");
            }
    
            console.log("Order ID récupéré :", orderId);
            console.log("Order Num récupéré :", orderNum);
    
            return { orderId, orderNum }; // Retourne les deux

        } catch (error) {
            console.error("Erreur dans updateLastNewOrder :", error);
            throw error;
        }
    };
    
    


    const handlePaygreenPayment = async () => {
        try {
            setLoading(true);
            console.log("Validation des informations utilisateur et adresse...");
            const validation = validateUserAndAddress(userInfos, isChecked, selectedAddress, isInDeliveryZone);
    
            if (!validation) {
                console.warn("Validation échouée : informations utilisateur ou adresse non valides.");
                setLoading(false);
                return;
            }
            console.log("Validation réussie.");
    
            const stripeAmount = Math.round(totalTTC * 100);
            console.log("Montant total en centimes pour Paygreen :", stripeAmount);
    
            console.log("Mise à jour ou récupération de la dernière commande...");
            const { orderId, orderNum } = await updateLastNewOrder(userInfos.id, stripeAmount);
    
            if (!orderId || !orderNum) {
                console.error("Échec : ID ou numéro de commande non valide après la mise à jour.");
                throw new Error("ID ou numéro de commande non valide après la mise à jour.");
            }
            console.log("ID de commande récupéré :", orderId);
            console.log("Numéro de commande récupéré :", orderNum);
    
            console.log("Tentative de création du paiement via Paygreen...");
            const paymentResult = await createInstantPayment(
                stripeAmount,
                `${orderNum}`, // Utilise `order_num` dans la description
                orderId // Passe `orderId` comme référence
            );
    
            console.log("Résultat du paiement :", paymentResult);
            if (paymentResult?.instantPayment?.data?.hosted_payment_url) {
                toast.success('Redirection vers la passerelle de paiement...');
                console.log("Redirection vers l'URL de paiement :", paymentResult.instantPayment.data.hosted_payment_url);
                window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
            } else {
                console.error("Erreur : URL de paiement non reçue dans la réponse.");
                throw new Error("Échec de la création du paiement Paygreen.");
            }
        } catch (error) {
            console.error("Erreur lors du traitement du paiement Paygreen :", error.message);
            toast.error("Le paiement avec Paygreen a échoué. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="order-validation-container">
            {loading && <div className="loader-overlay"><CircularProgress color="success" /></div>}
            <div className="order-validation-title flex-center">
                <h1>Voici votre récapitulatif {userInfos.firstname}</h1>
            </div>
            <div className="order-validation-action flex-center">
                <Button
                    className="btn-border btn-xl"
                    text="Changer la commande"
                    onClick={() => navigate('/home')}
                />
            </div>
            <div className="order-validation-items">
                {cart.length ? cart.map(item => <CartItem key={item.id} item={item} />) : <p>Votre panier est vide</p>}
            </div>
            <div className="order-validation-price flex-center"><OrderPrice /></div>
            <div className="flex-center"><TypeOfDelivery preorderEnabled={preorderEnabled} /></div>
            {preorderEnabled && (
                <div className="order-validation-preorder">
                    <div className="order-validation-date flex-center"><PreorderDate /></div>
                    <div className="order-validation-addresses flex-center"><Addresses /></div>
                </div>
            )}
            <div className="order-validation-condition flex-center">
                <OrderCondition isChecked={isChecked} setIsChecked={setIsChecked} />
            </div>
            <div className="order-validation-checkout flex-center">
                <Button className="btn-border btn-xl" text="Régler la commande" onClick={handlePaygreenPayment} />
            </div>
        </div>
    );
}

export default OrderValidation;

import React from 'react';
import { useSelector } from 'react-redux';
import PreOrder from './PreOrder';
import GroupOrders from './GoupOrders';

// Styles
import './RecipesNav.css';

function RecipesNav() {
    const isInDeliveryZone = JSON.parse(sessionStorage.getItem('isInDeliveryZone'));

    return (
        <>
            <nav className="recipes-nav">
                <div className="recipes-nav-links">
                    <a href="#Entrées">Entrées</a>
                    <a href="#Plats">Plats</a>
                    <a href="#Desserts">Desserts</a>
                    <a href="#Boissons">Boissons</a>
                </div>
            </nav>
            {isInDeliveryZone ? <PreOrder /> : <GroupOrders />}
        </>
    );
}

export default RecipesNav;
